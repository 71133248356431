// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.form-ex {
    margin: 0 10vw;
}

section.form-ex {
    padding: 5%;
    background: var(--clr-grey-9);
    border-radius: var(--radius);
}`, "",{"version":3,"sources":["webpack://./src/form-example/formexample.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,4BAA4B;AAChC","sourcesContent":["main.form-ex {\r\n    margin: 0 10vw;\r\n}\r\n\r\nsection.form-ex {\r\n    padding: 5%;\r\n    background: var(--clr-grey-9);\r\n    border-radius: var(--radius);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
