import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Flash from "../../ui-components/Flash";

export default function Params() {
  const [flash, setFlash] = useState({ type: "d-none", message: "" });
  const query = new URLSearchParams(useLocation().search);
  const param1 = query.get("param1");
  const param2 = query.get("param2");

  let responseMessage = "";

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/query-params/?param1=${param1}&param2=${param2}`
    )
      .then((response) => {
        console.log("Status code is ", response.status);
        console.log("Response is ", response);
        if (response.status !== 200) {
          setFlash({
            type: "alert-danger",
            message: "err code:" + response.status,
          });
        }
        return response.json();
      })
      .then((json) => {
        console.log("Json", json);
        if (!json.message && json.detail.length > 0) {
          setFlash((prev) => {
            return {
              ...prev,
              message: prev.message + " err from api: " + json.detail[0].msg,
            };
          });
          return;
        }
        // if (json.error) {
        //   console.log("Error from API: " + json.error);
        //   return false;
        // }
        responseMessage = `${json.message.param1} and ${json.message.param2}`;
        setFlash({
          type: "alert-primary",
          message: "Response message is " + responseMessage,
        });
      })
      .catch((error) => {
        // error on front
        setFlash({
          type: "alert-danger",
          message: `Frontend Error: ${error} `,
        });
      });
  }, []);

  return (
    <>
      <div>PathParams</div>
      <p>Input your query parameter in url</p>
      <Flash alertType={flash.type} alertMessage={flash.message} />
      <div>endParams</div>
    </>
  );
}
