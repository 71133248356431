const Input = (props) => {
  return (
    <div className={`mb-3 ${props.col}`}>
      <label htmlFor={props.name} className="form-label">
        {props.title}
      </label>
      <input
        type={props.type}
        className={`form-control ${props.err}`}
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
      />
      <div className={props.errDiv}>{props.errMsg}</div>
    </div>
  );
};

export default Input;

// useage

/* function to prepare
hasError
handleChange
*/
/* props
col
name
title
value
type
err
handleChange
err={hasError("lastname") ? "is-invalid" : ""}
errDiv={hasError("lastname") ? "text-danger" : "d-none"}
errMsg={"姓を入力してください"}
*/
