const Select = (props) => {
  return (
    <div className={`mb-3 ${props.col}`}>
      <label htmlFor="category_id" className="form-label">
        {props.title}
      </label>
      <select
        className="form-select"
        type={props.type}
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
      >
        <option value="">{props.placeholder}</option>
        {props.options.map((option) => {
          return (
            <option
              className="form-select"
              key={option.value}
              value={option.value}
              label={option.key}
            >
              {option.key}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;

/*
<Select
              title="カテゴリー"
              name="category_id"
              value={job.categoryid}
              placeholder="選択"
              options={categoryOptions}
              col=""
              handleChange={handleChange1}
            />
            */

/*
 const [categoryOptions, setCategoryOptions] = useState([
    { key: "opt1", value: 1 },
    { key: "opt2", value: 2 },
  ]);
 */
