import { books } from "./books";
import "./book.css";

const BookList = () => {
  document.title = "BookList App";
  return (
    <>
      <h1 className="mt-3 ms-3">Using CSS Grid system.</h1>
      <section className="booklist">
        {books.map((m, idx) => (
          <Book key={idx} {...m} />
        ))}
      </section>
    </>
  );
};

const Book = ({ img, title, author }) => {
  return (
    <article className="book">
      <img src={img} alt="" />
      <h2>{title}</h2>
      <h4 style={{ fontSize: "0.8rem", color: "#777" }}>{author}</h4>
    </article>
  );
};

export default BookList;
