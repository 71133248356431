import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Select from "../form-components/select";
import "./photo-shoot.css";
const PhotoShoot = () => {
  const videoRef = useRef(null);
  // ref for unmount moment, this still can access when unmount but state can not
  const cameraRef = useRef();

  const photoRef = useRef(null);
  const [storage, setStorage] = useState([]);
  // camera list
  const [camList, setCamList] = useState([]);
  // selected camera
  const [camId, setCamId] = useState("");
  // already connected video source
  const [videoSrc, setVideoSrc] = useState(null);
  // for message
  const [flash, setFlash] = useState(null);
  // streaming video
  const [streaming, setStreaming] = useState(false);

  useEffect(() => {
    const pictures = JSON.parse(localStorage.getItem("pictures") || "[]");
    setStorage(pictures);
  }, []);

  const getVideo = (camid) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: camid },
        // here, if not camid specified automatically choose top one
        // original config was: video: true,
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        setVideoSrc(video.srcObject);
        // video.play();
        setFlash("Click image to snap.");
        setStreaming(true);
      })
      .catch((error) => {
        setFlash(error);
      });
  };

  useEffect(() => {
    console.log("available media :", navigator.mediaDevices.enumerateDevices());
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices = devices.filter((device) => device.kind === "videoinput");
      let camOptionSelect = devices.map((m) => ({
        value: m.deviceId,
        key: m.label,
      }));
      console.log("Got camera list, ", devices);
      console.log("Got camera Option, ", camOptionSelect);
      setCamList(camOptionSelect);
    });
  }, [videoSrc]);

  useEffect(() => {
    // to use in unmount, testing
    cameraRef.current = videoSrc;
  }, [videoSrc]);

  useEffect(() => {
    return () => {
      console.log("Removing video at unmount.");
      cameraRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
      // videoSrc.getTracks().forEach(function (track) {
      //   track.stop();
      // });
    };
  }, []);

  useEffect(() => {
    // Create a new copy of the storage array
    let saved_pictures = [...storage];

    if (saved_pictures.length > 20) {
      // Keep the latest 20
      saved_pictures = saved_pictures.slice(-20);
    }
    localStorage.setItem("pictures", JSON.stringify(saved_pictures));
  }, [storage]);

  const connectVideo = () => {
    getVideo(camId);
  };

  const playVideo = () => {
    videoRef.current.play();
    setFlash("Streaming video...");
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setFlash("Streaming paused.");
  };

  const removeVideo = () => {
    videoSrc.getTracks().forEach(function (track) {
      track.stop();
    });
    setFlash("Camera connection was removed.");
    setStreaming(false);
  };

  const handleChange = (e) => {
    setCamId(e.target.value);
    console.log("Cam selected, ", camId);
  };

  const takePhoto = () => {
    if (!streaming) {
      setFlash("Please connect camera first.");
      return;
    }
    let video = videoRef.current;
    let photo = photoRef.current;

    const width = video.videoWidth;
    const height = video.videoHeight;

    // new dimensions (half size)
    const sWidth = width / 2;
    const sHeight = height / 2;

    photo.width = sWidth;
    photo.height = sHeight;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, sWidth, sHeight);
    // ctx.drawImage(videoElem, 0, 0);
    photo.toBlob((blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setStorage((pictures) => {
          return [...pictures, base64data];
        });
      };
      reader.readAsDataURL(blob);
    });
  };
  return (
    <>
      <div
        className="extra-space"
        onClick={takePhoto}
        style={{ backgroundColor: "black" }}
      ></div>
      <Select
        title="カメラ"
        name="cam_list"
        // value={job.categoryid}
        placeholder="選択"
        options={camList}
        col=""
        handleChange={handleChange}
      />
      <button className="btn btn-secondary" onClick={() => connectVideo(camId)}>
        接続
      </button>
      {/* <button className="btn btn-primary" onClick={playVideo}>
        再生
      </button>
      <button className="btn btn-warning" onClick={pauseVideo}>
        停止
      </button> */}
      <button className="btn btn-danger" onClick={removeVideo}>
        切断
      </button>
      {flash && <p>{flash}</p>}
      <div className="camera photo-shoot">
        <video
          className="photo-shoot"
          ref={videoRef}
          autoPlay={true}
          onClick={takePhoto}
        ></video>
      </div>
      <div className="result photo-shoot">
        <canvas ref={photoRef}></canvas>
      </div>
      {storage.map((base64data, index) => {
        return (
          <div className="album photo-shoot" key={index}>
            <img src={base64data} alt={`Image ${index}`}></img>
          </div>
        );
      })}
    </>
  );
};

export default PhotoShoot;
