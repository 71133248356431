// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom css for accordion of react-bootstarp */


/* this border radius has to set both  to be the same */

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.accordion-item:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.accordion-item:last-of-type .accordion-button {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.accordion-item:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}


/* text in header */

.accordion-button {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/ui-components/accordion.css"],"names":[],"mappings":"AAAA,gDAAgD;;;AAGhD,uDAAuD;;AAEvD;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;;;AAGA,mBAAmB;;AAEnB;IACI,iBAAiB;AACrB","sourcesContent":["/* custom css for accordion of react-bootstarp */\r\n\r\n\r\n/* this border radius has to set both  to be the same */\r\n\r\n.accordion-item:first-of-type .accordion-button {\r\n    border-top-left-radius: 7px;\r\n    border-top-right-radius: 7px;\r\n}\r\n\r\n.accordion-item:first-of-type {\r\n    border-top-left-radius: 7px;\r\n    border-top-right-radius: 7px;\r\n}\r\n\r\n.accordion-item:last-of-type .accordion-button {\r\n    border-bottom-left-radius: 7px;\r\n    border-bottom-right-radius: 7px;\r\n}\r\n\r\n.accordion-item:last-of-type {\r\n    border-bottom-left-radius: 7px;\r\n    border-bottom-right-radius: 7px;\r\n}\r\n\r\n\r\n/* text in header */\r\n\r\n.accordion-button {\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
