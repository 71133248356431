import { useEffect, useState } from "react";
import "./showwindowsize.css";

const ShowWindowSize = () => {
  const [size, setSize] = useState(window.innerWidth);

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkSize);
    // need clean up event listener
    // return () => {
    //   window.removeEventListener("resize", checkSize);
    // };
  }, []);

  // mouse postion
  const [cord, setCord] = useState({ x: 0, y: 0 });
  const onMouseMove = (e) => {
    // setCord({ ...cord, y: e.clientY + e.target.offsetTop });
    setCord({ ...cord, y: e.pageY - e.target.offsetTop, x: e.pageX });
  };

  return (
    <>
      <h1>window</h1>
      <h2>{size} PX</h2>
      <h1>mouse</h1>
      <h2>
        ({cord.x},{cord.y}){" "}
      </h2>
      <div className="mouse" onMouseMove={onMouseMove}>
        Mouse Area
      </div>
    </>
  );
};

export default ShowWindowSize;
