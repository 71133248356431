const Flash = (props) => {
  return (
    <div className={`alert ${props.alertType} mt-2`} role="alert">
      {props.alertMessage}
    </div>
  );
};

export default Flash;

/* useage


<Flash alertType={flash.type} alertMessage={flash.message} />

use state together for Flash
const [flash, setFlash] = useState({ type: "d-none", message: "" });

place component somewhere and 
example:
   if (valerrors.length > 0) {
      setFlash({
        type: "alert-danger",
        message: "フォーム入力に不備があります",
      });
      return false;
    } else {
      setFlash({
        type: "d-none",
        message: "",
      });
      setShowData(true);
    }


*/
