// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
=============== 
Home
===============
*/

main.sidebarhome {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-toggle {
    position: fixed;
    top: 2rem;
    left: 3rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.btn {
    text-transform: uppercase;
    background: var(--clr-black);
    color: var(--clr-white);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    transition: var(--transition);
    font-size: 0.875rem;
    border: 2px solid var(--clr-black);
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
    margin: 0.5rem;
}

.btn:hover {
    color: var(--clr-black);
    background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/sidebar/home.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;IACI,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,UAAU;IACV,eAAe;IACf,uBAAuB;IACvB,yBAAyB;IACzB,2BAA2B;IAC3B,6BAA6B;IAC7B,eAAe;IACf,yCAAyC;AAC7C;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,qBAAqB;IACzB;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,uBAAuB;IACvB,yBAAyB;IACzB,8BAA8B;IAC9B,qBAAqB;IACrB,6BAA6B;IAC7B,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;IACf,wCAAwC;IACxC,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;AAC3B","sourcesContent":["/*\r\n=============== \r\nHome\r\n===============\r\n*/\r\n\r\nmain.sidebarhome {\r\n    min-height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.sidebar-toggle {\r\n    position: fixed;\r\n    top: 2rem;\r\n    left: 3rem;\r\n    font-size: 2rem;\r\n    background: transparent;\r\n    border-color: transparent;\r\n    color: var(--clr-primary-5);\r\n    transition: var(--transition);\r\n    cursor: pointer;\r\n    animation: bounce 2s ease-in-out infinite;\r\n}\r\n\r\n@keyframes bounce {\r\n    0% {\r\n        transform: scale(1);\r\n    }\r\n    50% {\r\n        transform: scale(1.5);\r\n    }\r\n    100% {\r\n        transform: scale(1);\r\n    }\r\n}\r\n\r\n.btn {\r\n    text-transform: uppercase;\r\n    background: var(--clr-black);\r\n    color: var(--clr-white);\r\n    padding: 0.375rem 0.75rem;\r\n    letter-spacing: var(--spacing);\r\n    display: inline-block;\r\n    transition: var(--transition);\r\n    font-size: 0.875rem;\r\n    border: 2px solid var(--clr-black);\r\n    cursor: pointer;\r\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);\r\n    border-radius: var(--radius);\r\n    margin: 0.5rem;\r\n}\r\n\r\n.btn:hover {\r\n    color: var(--clr-black);\r\n    background: transparent;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
