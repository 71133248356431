// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booklist {
    width: 90vw;
    max-width: 1170px;
    margin: 5rem auto;
    display: grid;
    gap: 2rem 3vw;
}

.book {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem 2.5vw;
}


/* resize image */

.book img {
    width: 100%;
    height: auto;
}

.book h2 {
    margin-top: 0.5rem;
    font-size: 1.5vw;
}


/* use media to limit max size of vw size */

@media screen and (min-width:768px) {
    .book h2 {
        font-size: 1rem;
    }
}

@media screen and (min-width:768px) {
    .booklist {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width:768px) {
    .booklist {
        grid-template-columns: repeat(3, 1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/book/book.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA,iBAAiB;;AAEjB;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;;AAGA,2CAA2C;;AAE3C;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".booklist {\r\n    width: 90vw;\r\n    max-width: 1170px;\r\n    margin: 5rem auto;\r\n    display: grid;\r\n    gap: 2rem 3vw;\r\n}\r\n\r\n.book {\r\n    background-color: #fff;\r\n    border-radius: 1rem;\r\n    padding: 1rem 2.5vw;\r\n}\r\n\r\n\r\n/* resize image */\r\n\r\n.book img {\r\n    width: 100%;\r\n    height: auto;\r\n}\r\n\r\n.book h2 {\r\n    margin-top: 0.5rem;\r\n    font-size: 1.5vw;\r\n}\r\n\r\n\r\n/* use media to limit max size of vw size */\r\n\r\n@media screen and (min-width:768px) {\r\n    .book h2 {\r\n        font-size: 1rem;\r\n    }\r\n}\r\n\r\n@media screen and (min-width:768px) {\r\n    .booklist {\r\n        grid-template-columns: repeat(4, 1fr);\r\n    }\r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n    .booklist {\r\n        grid-template-columns: repeat(3, 1fr);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
