// import React, { useState, useEffect } from "react";
import React from "react";
// import { useNavigate } from "react-router-dom";
import linelogo from "./btn_login_base.png";
import linelogohover from "./btn_login_hover.png";
import "./linelogin.css";

// without backend

export default function LineLogin() {
  // for ramdom state string to put in uri query of line
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const randomid = makeid(10);
  const URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657026517&redirect_uri=https://app.legas.jp/line-logon-callback&state=${randomid}&scope=profile%20openid&nonce=09876xyz`;

  return (
    <>
      <div>Line Login Demo</div>
      <div className="line-login ms-3 mt-3">
        {/* <a href={URL} target="_blank" rel="noopener noreferrer"> */}
        <a href={URL} target="_self">
          <img src={linelogo} alt="lineloginimage" />
          <img className="img-top" src={linelogohover} alt="lineloginimage" />
        </a>
      </div>
    </>
  );
}
