import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Flash from "../../ui-components/Flash";

export default function PathParams() {
  const [flash, setFlash] = useState({ type: "d-none", message: "" });
  const params = useParams();
  console.log("Path param is ", params);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/path-params/${params.id}`)
      .then((response) => {
        console.log("Status code is ", response.status);
        console.log("Response is ", response);
        if (response.status !== 200) {
          setFlash({
            type: "alert-danger",
            message: "Invalid response code:" + response.status,
          });
          return false;
        }
        return response.json();
      })
      .then((json) => {
        console.log("Json", json);
        if (json.error) {
          console.log("Effor from API: " + json.error);
          return false;
        }
        setFlash({
          type: "alert-primary",
          message: "Response message is " + json.message,
        });
      })
      .catch((error) => {
        // error on front
        setFlash({
          type: "alert-danger",
          message: `Frontend Error: ${error} `,
        });
      });
  }, []);

  return (
    <>
      <div>PathParams</div>
      <p>Your path parameter is {params.id}</p>
      <Flash alertType={flash.type} alertMessage={flash.message} />
      <div>endParams</div>
    </>
  );
}
