import React, { useState, useEffect } from "react";
import Loading from "../effect-components/Loading";
import Accordion from "react-bootstrap/Accordion";
import questions from "./data";
import "./qanda.css";
import "../ui-components/accordion.css";
import SingleAccordion from "../ui-components/SingleAccordion";

export default function QandA() {
  // const {id , title, info} = questions
  return (
    <>
      <section className="qanda container">
        <div className="row">
          <div className="col-md-4">
            <h1>Rct bs accordion</h1>
          </div>
          <article className="col-md-8">
            <Accordion defaultActivieKey={"0"}>
              {questions.map((question) => (
                <Accordion.Item eventKey={question.id} key={question.id}>
                  <Accordion.Header>{question.title}</Accordion.Header>

                  <Accordion.Body>{question.info}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </article>
        </div>
      </section>
      <section className="qanda container">
        <div className="row">
          <div className="col-md-4">
            <h1>Single accordion</h1>
          </div>
          <div className="col-md-8">
            <div className="single-qa">
              <SingleAccordion title="Title" info="Info dayo" />
            </div>
            <div className="single-qa">
              <SingleAccordion title="Title" info="Info dayo" />
            </div>
            <div className="single-qa">
              <SingleAccordion title="Title" info="Info dayo" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
