export const books = [
  {
    id: 1,
    img: "https://m.media-amazon.com/images/I/51hQl6vogaL.jpg",
    title: "図解・気象学入門　原理からわかる雲・雨・気温・風・天気図",
    author: "古川武彦",
    desc: "数十トンもある雲が落ちてこないのはなぜ？　高原は太陽に近いのになぜ涼しいの？　ジェット気流って何？　高気圧や低気圧はなぜできるの？　日頃の疑問が氷解します！　原理がわかると、知らなかった気象の姿が見えてくる――気象と天気のしくみを原理から詳しく丁寧に解説した「わかる」入門書。やさしい語り口ながらも気象学用語の多くを網羅。気象予報士を目指すスタートにも最適。",
  },
  {
    id: 2,
    img: "https://m.media-amazon.com/images/I/51lpK1KcNeL._SY346_.jpg",
    title:
      "コンピュータはなぜ動くのか　知っておきたいハードウエア＆ソフトウエアの基礎知識",
    author: "矢沢久雄",
    desc: "本書はベストセラー『プログラムはなぜ動くのか』の姉妹本として、この1冊だけ読んでも理解できる、わかりやすい内容です。内容的には、入力・演算・出力から始まり、ハードウェアとソフトウェア、プログラミング、データベース、ネットワーク、SEの業務知識と、コンピュータの初等教育で扱われる内容をほぼひととおり網羅しています。用語をできる限り並べるようないわゆる「テキスト」とは異なり、コンピュータを理解するうえで重要なポイントをできるだけ掘り下げようとしている姿勢が大きな特徴です",
  },
  {
    id: 3,
    img: "https://m.media-amazon.com/images/I/51xAvgeOiLL.jpg",
    title: "ポストモーテム みずほ銀行システム障害 事後検証報告",
    author: "日経コンピュータ",
    desc: "なぜ繰り返すのか、メガバンクの失敗に学ぶ。みずほ銀行で2021年2月からの12カ月間に11回ものシステム障害が発生した。2002年、2011年と大規模システム障害を起こし、それを反省して2019年までに勘定系システムを全面刷新したみずほ銀行だったが、トラブルは繰り返された",
  },
  {
    id: 4,
    img: "https://m.media-amazon.com/images/I/51mlCblmH2L.jpg",
    title: "文系でもよくわかる　世界の仕組みを物理学で知る",
    author: "松原 隆彦",
    desc: "最先端の物理学が、金融の世界を制している。輪廻転生は物理学では正しい。AIが人間を不死にする。日本の宇宙論、物理学の第１人者が解き明かす、新しい世界の見方。最先端の物理学を誰でも分かるように解き明かします。量子論によって、我々の人生が変わる！",
  },
  {
    id: 5,
    img: "https://m.media-amazon.com/images/I/51psv+1tR5L.jpg",
    title: "子どもの脳を傷つける親たち ＮＨＫ出版新書",
    author: "友田 明美",
    desc: "不適切なかかわりが、子どもの脳を変形させる脳科学が明らかにした驚くべき事実「子どもの前での夫婦喧嘩」、「心ない言葉」、「スマホ・ネグレクト」に「きょうだい間の差別」──。マルトリートメント（不適切な養育）が子どもの脳を「物理的」に傷つけ、学習欲の低下や非行、うつや統合失調症などの病を引き起こすことが明らかになった。脳研究に取り組む小児精神科医が、科学的見地から子どもの脳を解明し、傷つきから守る方途と、健全なこころの発達に不可欠である愛着形成の重要性を説く。",
  },
  {
    id: 1,
    img: "https://m.media-amazon.com/images/I/51hQl6vogaL.jpg",
    title: "図解・気象学入門　原理からわかる雲・雨・気温・風・天気図",
    author: "古川武彦",
    desc: "数十トンもある雲が落ちてこないのはなぜ？　高原は太陽に近いのになぜ涼しいの？　ジェット気流って何？　高気圧や低気圧はなぜできるの？　日頃の疑問が氷解します！　原理がわかると、知らなかった気象の姿が見えてくる――気象と天気のしくみを原理から詳しく丁寧に解説した「わかる」入門書。やさしい語り口ながらも気象学用語の多くを網羅。気象予報士を目指すスタートにも最適。",
  },
  {
    id: 2,
    img: "https://m.media-amazon.com/images/I/51lpK1KcNeL._SY346_.jpg",
    title:
      "コンピュータはなぜ動くのか　知っておきたいハードウエア＆ソフトウエアの基礎知識",
    author: "矢沢久雄",
    desc: "本書はベストセラー『プログラムはなぜ動くのか』の姉妹本として、この1冊だけ読んでも理解できる、わかりやすい内容です。内容的には、入力・演算・出力から始まり、ハードウェアとソフトウェア、プログラミング、データベース、ネットワーク、SEの業務知識と、コンピュータの初等教育で扱われる内容をほぼひととおり網羅しています。用語をできる限り並べるようないわゆる「テキスト」とは異なり、コンピュータを理解するうえで重要なポイントをできるだけ掘り下げようとしている姿勢が大きな特徴です",
  },
  {
    id: 3,
    img: "https://m.media-amazon.com/images/I/51xAvgeOiLL.jpg",
    title: "ポストモーテム みずほ銀行システム障害 事後検証報告",
    author: "日経コンピュータ",
    desc: "なぜ繰り返すのか、メガバンクの失敗に学ぶ。みずほ銀行で2021年2月からの12カ月間に11回ものシステム障害が発生した。2002年、2011年と大規模システム障害を起こし、それを反省して2019年までに勘定系システムを全面刷新したみずほ銀行だったが、トラブルは繰り返された",
  },
  {
    id: 4,
    img: "https://m.media-amazon.com/images/I/51mlCblmH2L.jpg",
    title: "文系でもよくわかる　世界の仕組みを物理学で知る",
    author: "松原 隆彦",
    desc: "最先端の物理学が、金融の世界を制している。輪廻転生は物理学では正しい。AIが人間を不死にする。日本の宇宙論、物理学の第１人者が解き明かす、新しい世界の見方。最先端の物理学を誰でも分かるように解き明かします。量子論によって、我々の人生が変わる！",
  },
  {
    id: 5,
    img: "https://m.media-amazon.com/images/I/51psv+1tR5L.jpg",
    title: "子どもの脳を傷つける親たち ＮＨＫ出版新書",
    author: "友田 明美",
    desc: "不適切なかかわりが、子どもの脳を変形させる脳科学が明らかにした驚くべき事実「子どもの前での夫婦喧嘩」、「心ない言葉」、「スマホ・ネグレクト」に「きょうだい間の差別」──。マルトリートメント（不適切な養育）が子どもの脳を「物理的」に傷つけ、学習欲の低下や非行、うつや統合失調症などの病を引き起こすことが明らかになった。脳研究に取り組む小児精神科医が、科学的見地から子どもの脳を解明し、傷つきから守る方途と、健全なこころの発達に不可欠である愛着形成の重要性を説く。",
  },
];
