import react from "react";
import Review from "./Review";
import "./reviews.css";

function Reviews() {
  return (
    <main className="reviews">
      <section className=" reviews container">
        <div className="title">
          <h2>our reviews</h2>
          <div className="underline"></div>
        </div>
        <Review />
      </section>
    </main>
  );
}

export default Reviews;
