import React from "react";
import Modal from "./Modal_context";
import Sidebar from "./Sidebar";
import Home from "./Home";
import { AppProvider } from "./context";

function SidebarAndModal() {
  return (
    <>
      <AppProvider>
        <Home />
        <Modal />
        <Sidebar />
      </AppProvider>
    </>
  );
}

export default SidebarAndModal;

// this file is the top component in this module,
