import React, { useState } from "react";
import Input from "../form-components/Input";
import Flash from "../ui-components/Flash";
import "./formexample.css";

export default function FormExample() {
  const [userInfo, setUserInfo] = useState({
    name: "",
    age: "",
  });
  const [valerrors, setValErrors] = useState([]);
  // only for this example
  const [showData, setShowData] = useState(false);

  // use this for Flash
  const [flash, setFlash] = useState({ type: "d-none", message: "" });
  //handleChange to put inot form element
  const handleChange = (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    setUserInfo({ ...userInfo, [name]: value });
  };

  // this one is for each form elemt, set in err and errMsg
  function hasError(key) {
    return valerrors.indexOf(key) !== -1;
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    let valerrors = []; // if don't have this one not update when validation error
    if (userInfo.name.length < 3) {
      valerrors.push("name");
    }
    if (userInfo.age < 10 || userInfo.age > 80) {
      valerrors.push("age");
    }
    setValErrors(valerrors);

    if (valerrors.length > 0) {
      setFlash({
        type: "alert-danger",
        message: "フォーム入力に不備があります",
      });
      return false;
    } else {
      setFlash({
        type: "d-none",
        message: "",
      });
      setShowData(true);
    }
  };

  return (
    <>
      <main className="form-ex">
        <section className="form-ex">
          <form className="pt-3" onSubmit={handleSubmit}>
            <div className="row">
              <Input
                name="name"
                handleChange={handleChange}
                title="名前"
                value={userInfo.name}
                err={hasError("name") ? "is-invalid" : ""}
                errDiv={hasError("name") ? "text-danger" : "d-none"}
                errMsg={"名前が短すぎます"}
              />
              <Input
                name="age"
                handleChange={handleChange}
                title="年齢"
                value={userInfo.age}
                type="number"
                err={hasError("age") ? "is-invalid" : ""}
                errDiv={hasError("age") ? "text-danger" : "d-none"}
                errMsg={"年齢が現実的ではありません"}
              />
            </div>
            <button className="btn btn-primary">データ確認</button>
          </form>
        </section>
        <hr />
        <Flash alertType={flash.type} alertMessage={flash.message} />
        <table className="table">
          <tbody>
            {showData &&
              Object.keys(userInfo).map(function (key, index) {
                return (
                  <tr key={index}>
                    <td style={{ width: "25%" }}>{`${key}`} </td>
                    <td>{`: ${userInfo[key]}`}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </main>
    </>
  );
}
