// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
=============== 
Modal
===============
*/

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    display: grid;
    place-items: center;
    transition: var(--transition);
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transform: translateY(150px);
    transition: visibility 0.1s linear, opacity 0.1s linear, transform 0.1s ease-in-out;
}


/* OPEN/CLOSE MODAL */

.show-modal {
    visibility: visible;
    z-index: 10;
    opacity: 1;
    transform: translateX(0px);
}

.modal-container {
    background: var(--clr-white);
    border-radius: var(--radius);
    width: 90vw;
    height: 30vh;
    max-width: var(--fixed-width);
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
    box-shadow: var(--light-shadow);
}

.close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-red-dark);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/sidebar/modal.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,4BAA4B;IAC5B,mFAAmF;AACvF;;;AAGA,qBAAqB;;AAErB;IACI,mBAAmB;IACnB,WAAW;IACX,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,uBAAuB;IACvB,yBAAyB;IACzB,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":["/*\r\n=============== \r\nModal\r\n===============\r\n*/\r\n\r\n.modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    /* background: rgba(0, 0, 0, 0.5); */\r\n    display: grid;\r\n    place-items: center;\r\n    transition: var(--transition);\r\n    visibility: hidden;\r\n    z-index: -1;\r\n    opacity: 0;\r\n    transform: translateY(150px);\r\n    transition: visibility 0.1s linear, opacity 0.1s linear, transform 0.1s ease-in-out;\r\n}\r\n\r\n\r\n/* OPEN/CLOSE MODAL */\r\n\r\n.show-modal {\r\n    visibility: visible;\r\n    z-index: 10;\r\n    opacity: 1;\r\n    transform: translateX(0px);\r\n}\r\n\r\n.modal-container {\r\n    background: var(--clr-white);\r\n    border-radius: var(--radius);\r\n    width: 90vw;\r\n    height: 30vh;\r\n    max-width: var(--fixed-width);\r\n    text-align: center;\r\n    display: grid;\r\n    place-items: center;\r\n    position: relative;\r\n    box-shadow: var(--light-shadow);\r\n}\r\n\r\n.close-modal-btn {\r\n    position: absolute;\r\n    top: 1rem;\r\n    right: 1rem;\r\n    font-size: 2rem;\r\n    background: transparent;\r\n    border-color: transparent;\r\n    color: var(--clr-red-dark);\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
