import { useEffect, useState, useRef } from "react";
import Select from "../form-components/select";
import "./camera.css";
const Camera = () => {
  const videoRef = useRef(null);
  // camera list
  const [camList, setCamList] = useState([]);
  // selected camera
  const [camId, setCamId] = useState("");
  // already connected video source
  const [videoSrc, setVideoSrc] = useState(null);
  // for message
  const [flash, setFlash] = useState(null);

  const getVideo = (camid) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: camid },
        // here, if not camid specified automatically choose top one
        // original config was: video: true,
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        setVideoSrc(video.srcObject);
        // video.play();
        setFlash("Camera is ready, click play");
      })
      .catch((error) => {
        setFlash(error);
      });
  };

  useEffect(() => {
    console.log("available media :", navigator.mediaDevices.enumerateDevices());
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices = devices.filter((device) => device.kind === "videoinput");
      let camOptionSelect = devices.map((m) => ({
        value: m.deviceId,
        key: m.label,
      }));
      console.log("Got camera list, ", devices);
      console.log("Got camera Option, ", camOptionSelect);
      setCamList(camOptionSelect);
    });
    return function cleanup() {
      //   videoRef.current.remove();
    };
  }, [videoSrc]);

  const connectVideo = () => {
    getVideo(camId);
  };

  const playVideo = () => {
    videoRef.current.play();
    setFlash("Streaming video...");
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setFlash("Streaming paused.");
  };

  const removeVideo = () => {
    videoSrc.getTracks().forEach(function (track) {
      track.stop();
    });
    setFlash("Camera connection was removed.");
  };

  const handleChange = (e) => {
    setCamId(e.target.value);
    console.log("Cam selected, ", camId);
  };

  return (
    <>
      <Select
        title="カメラ"
        name="cam_list"
        // value={job.categoryid}
        placeholder="選択"
        options={camList}
        col=""
        handleChange={handleChange}
      />
      <button className="btn btn-secondary" onClick={() => connectVideo(camId)}>
        接続
      </button>
      <button className="btn btn-primary" onClick={playVideo}>
        再生
      </button>
      <button className="btn btn-warning" onClick={pauseVideo}>
        停止
      </button>
      <button className="btn btn-danger" onClick={removeVideo}>
        切断
      </button>
      {flash && <p>{flash}</p>}
      <div className="camera">
        <video ref={videoRef} autoPlay={false}></video>
      </div>
    </>
  );
};

export default Camera;
