// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qanda.container {
    padding: 5vh 5vw;
    background-color: rgb(59, 126, 189);
    border-radius: 0.5rem;
    width: 90vw;
    margin: 5vh auto;
}

.qanda.container h1 {
    color: var(--clr-white);
}

.single-qa {
    background: var(--clr-white);
    border-radius: var(--radius);
}


/* .single-qa .btn {
    background-color: var(--clr-grey-4);
    font-size: 1rem;
} */`, "",{"version":3,"sources":["webpack://./src/q&a/qanda.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mCAAmC;IACnC,qBAAqB;IACrB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;AAChC;;;AAGA;;;GAGG","sourcesContent":[".qanda.container {\r\n    padding: 5vh 5vw;\r\n    background-color: rgb(59, 126, 189);\r\n    border-radius: 0.5rem;\r\n    width: 90vw;\r\n    margin: 5vh auto;\r\n}\r\n\r\n.qanda.container h1 {\r\n    color: var(--clr-white);\r\n}\r\n\r\n.single-qa {\r\n    background: var(--clr-white);\r\n    border-radius: var(--radius);\r\n}\r\n\r\n\r\n/* .single-qa .btn {\r\n    background-color: var(--clr-grey-4);\r\n    font-size: 1rem;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
