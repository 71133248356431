import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Flash from "../../ui-components/Flash";
import "./linelogin.css";

export default function LineLogonCallback() {
  const [flash, setFlash] = useState({ type: "d-none", message: "" });
  // const [jwt, setJwt] = useState("");
  const [name, setName] = useState("logging in..");
  const [picture, setPicture] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get("code");

  useEffect(() => {
    // check jwt first
    let t = localStorage.getItem("jwt");
    if (t) {
      var jwtArr = t.split("&&$&&");
      const accessToken = jwtArr[0];
      setName(jwtArr[1]);
      setPicture(jwtArr[2]);
      verifyAccessToken(accessToken).then((response) => {
        if (response.status !== 200) {
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
        }
      });
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const myBody = `grant_type=authorization_code&code=${code}&redirect_uri=https://app.legas.jp/line-logon-callback&client_id=1657026517&client_secret=4dd0681a6d261274f6f7bfd93faf1ad5`;
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: myBody,
    };
    // to get access and id token
    fetch(`https://api.line.me/oauth2/v2.1/token`, requestOptions)
      .then((response) => {
        console.log("Status code is ", response.status);
        console.log("Response is ", response);
        if (response.status !== 200) {
          setFlash({
            type: "alert-danger",
            message: "err code:" + response.status,
          });
        }
        return response.json();
      })
      .then((json) => {
        console.log("Json", json);
        // setJwt(json.access_token);
        const accToken = json.access_token;
        // get user profile from id_token
        getUserProfile(json.id_token)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            setName(json.name);
            setPicture(json.picture);
            localStorage.setItem(
              "jwt",
              accToken + "&&$&&" + json.name + "&&$&&" + json.picture
            );
          });
        setIsLoggedIn(true);
      })
      .catch((error) => {
        // error on front
        setFlash({
          type: "alert-danger",
          message: `Frontend Error: ${error} `,
        });
        setIsLoggedIn(false);
      });
  }, []);

  async function getUserProfile(idToken) {
    // to get profile from access id token
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const secondBody = `id_token=${idToken}&client_id=1657026517`;
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: secondBody,
    };
    return fetch(`https://api.line.me/oauth2/v2.1/verify`, requestOptions);
  }

  async function verifyAccessToken(accessToken) {
    return fetch(
      `https://api.line.me/oauth2/v2.1/verify?access_token=${accessToken}`
    );
  }

  return (
    <>
      <div className="profile-container">
        {isLoggedIn ? (
          <div>you are logged in</div>
        ) : (
          <div>not loggedin yet </div>
        )}
        {isLoggedIn && (
          <>
            <div className="line-name">{name}</div>
            <div className="line-picture">
              <img className="line-picture" src={picture} alt="mypic" />
            </div>
          </>
        )}
        <Flash alertType={flash.type} alertMessage={flash.message} />
      </div>
      <div></div>
    </>
  );
}
