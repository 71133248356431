// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 3rem auto;
}

ul.users {
    padding-left: 2rem;
    padding-right: 2rem;
}

.users li {
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--clr-white);
    padding: 1rem 2rem;
    border-radius: var(--radius);
    text-align: left;
}

.users img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
}

.users h4 {
    margin-bottom: 0.15rem;
}

.users a {
    color: var(--clr-grey-5);
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/userlist/userlist.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,kBAAkB;IAClB,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;AAC9B","sourcesContent":[".users {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\r\n    gap: 2rem;\r\n    margin: 3rem auto;\r\n}\r\n\r\nul.users {\r\n    padding-left: 2rem;\r\n    padding-right: 2rem;\r\n}\r\n\r\n.users li {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    background: var(--clr-white);\r\n    padding: 1rem 2rem;\r\n    border-radius: var(--radius);\r\n    text-align: left;\r\n}\r\n\r\n.users img {\r\n    width: 50px;\r\n    height: 50px;\r\n    border-radius: 50%;\r\n    margin-right: 1rem;\r\n}\r\n\r\n.users h4 {\r\n    margin-bottom: 0.15rem;\r\n}\r\n\r\n.users a {\r\n    color: var(--clr-grey-5);\r\n    text-transform: capitalize;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
