import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import BookList from "./book/BookList";
import ShowWindowSize from "./show-window-size/ShowWindowSize";
import UserList from "./userlist/UserList";
import TourList from "./tour/TourList";
import ClientInfo from "./client-info/ClientInfo";
import Camera from "./camera-stream/Camera";
import Reviews from "./reviews/Reviews";
import QandA from "./q&a/QandA";
import FoodMenu from "./foodmenu/FoodMenu";
import Slider from "./slider/Slider";
import FormExample from "./form-example/FormExample";
import PhotoShoot from "./photo-shoot/PhotoShoot";
import Navbar from "./navbar/Navbar";
import SidebarAndModal from "./sidebar/SidebarAndModal";
import PathParams from "./apis/path-params/PathParams";
import QueryParams from "./apis/path-params/QueryParams";
import LineLogin from "./apis/path-params/LineLogin";
import LineLogonCallback from "./apis/path-params/LineLogonCallback";

function App() {
  const Top = () => {
    return (
      <>
        <div className="mt-3 mb-1">UI</div>
        <div>
          <Link to="navbar">navbar</Link>
        </div>
        <div>
          <Link to="sidebar">sidebar and modal</Link>
        </div>
        <div>
          <Link to="booklist">booklist</Link>
        </div>
        <div>
          <Link to="userlist">userlist</Link>
        </div>
        <div>
          <Link to="tourlist">tourlist</Link>
        </div>
        <div>
          <Link to="reviews">review ui</Link>
        </div>
        <div>
          <Link to="qanda">q and a</Link>
        </div>
        <div>
          <Link to="foodmenu">foodmenu</Link>
        </div>
        <div>
          <Link to="slider">slider</Link>
        </div>
        <div>
          <Link to="formexample">form example</Link>
        </div>

        <div className="mt-3 mb-1">Utility</div>

        <div>
          <Link to="clientinfo">clientinfo</Link>
        </div>
        <div>
          <Link to="camerastream">camera stream</Link>
        </div>
        <div>
          <Link to="photoshoot">photo shoot</Link>
        </div>
        <div>
          <Link to="window">window func</Link>
        </div>

        <div className="mt-3 mb-1">APIs</div>

        <div>
          <Link to="pathparams/999">path params</Link>
        </div>
        <div>
          <Link to="queryparams">query params</Link>
        </div>
        <div>
          <Link to="linelogin">LINE login</Link>
        </div>
      </>
    );
  };
  return (
    <>
      <div>
        <Router>
          <div>
            <Link to="/">Top</Link>
          </div>
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="booklist" element={<BookList />} />
            <Route path="window" element={<ShowWindowSize />} />
            <Route path="userlist" element={<UserList />} />
            <Route path="tourlist" element={<TourList />} />
            <Route path="clientinfo" element={<ClientInfo />} />
            <Route path="camerastream" element={<Camera />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="qanda" element={<QandA />} />
            <Route path="foodmenu" element={<FoodMenu />} />
            <Route path="slider" element={<Slider />} />
            <Route path="formexample" element={<FormExample />} />
            <Route path="photoshoot" element={<PhotoShoot />} />
            <Route path="navbar" element={<Navbar />} />
            <Route path="sidebar" element={<SidebarAndModal />} />
            <Route path="pathparams/:id" element={<PathParams />} />
            <Route path="queryparams" element={<QueryParams />} />
            <Route path="linelogin" element={<LineLogin />} />
            <Route path="line-logon-callback" element={<LineLogonCallback />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
