// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
    width: 60%;
    max-width: 100%;
    height: auto;
    border-radius: var(--radius);
}

.camera {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/camera-stream/camera.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["video {\r\n    width: 60%;\r\n    max-width: 100%;\r\n    height: auto;\r\n    border-radius: var(--radius);\r\n}\r\n\r\n.camera {\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
