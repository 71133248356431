// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `    .line-login {
        /* width: 130px;
        height: 195px; */
        position: relative;
        display: inline-block;
    }
    
    .line-login .img-top {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }
    
    .line-login:hover .img-top {
        display: inline;
    }
    
    .profile-container {
        display: flex;
        flex-flow: column;
        justify-content: center;
    }
    
    .line-name {
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem auto;
    }
    
    .line-picture {
        width: 100px;
        height: auto;
        border-radius: 50%;
        margin: 1rem auto;
    }`, "",{"version":3,"sources":["webpack://./src/apis/path-params/linelogin.css"],"names":[],"mappings":"IAAI;QACI;wBACgB;QAChB,kBAAkB;QAClB,qBAAqB;IACzB;;IAEA;QACI,aAAa;QACb,kBAAkB;QAClB,MAAM;QACN,OAAO;QACP,WAAW;IACf;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,iBAAiB;QACjB,uBAAuB;IAC3B;;IAEA;QACI,eAAe;QACf,iBAAiB;QACjB,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,YAAY;QACZ,kBAAkB;QAClB,iBAAiB;IACrB","sourcesContent":["    .line-login {\r\n        /* width: 130px;\r\n        height: 195px; */\r\n        position: relative;\r\n        display: inline-block;\r\n    }\r\n    \r\n    .line-login .img-top {\r\n        display: none;\r\n        position: absolute;\r\n        top: 0;\r\n        left: 0;\r\n        z-index: 99;\r\n    }\r\n    \r\n    .line-login:hover .img-top {\r\n        display: inline;\r\n    }\r\n    \r\n    .profile-container {\r\n        display: flex;\r\n        flex-flow: column;\r\n        justify-content: center;\r\n    }\r\n    \r\n    .line-name {\r\n        font-size: 2rem;\r\n        font-weight: bold;\r\n        margin: 1rem auto;\r\n    }\r\n    \r\n    .line-picture {\r\n        width: 100px;\r\n        height: auto;\r\n        border-radius: 50%;\r\n        margin: 1rem auto;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
