// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video.photo-shoot {
  width: 100px;
  max-width: 100%;
  height: auto;
  border-radius: var(--radius);
}

.camera.photo-shoot {
  text-align: left;
}

.result.photo-shoot {
  margin-top: 5px;
  border-radius: var(--radius);
  display: none;
}

div.album.photo-shoot {
  display: inline;
}

.album.photo-shoot img {
  margin: 3px;
  width: 90px;
  object-fit: cover;
}

.extra-space {
  height: 80vh;
}
`, "",{"version":3,"sources":["webpack://./src/photo-shoot/photo-shoot.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":["video.photo-shoot {\r\n  width: 100px;\r\n  max-width: 100%;\r\n  height: auto;\r\n  border-radius: var(--radius);\r\n}\r\n\r\n.camera.photo-shoot {\r\n  text-align: left;\r\n}\r\n\r\n.result.photo-shoot {\r\n  margin-top: 5px;\r\n  border-radius: var(--radius);\r\n  display: none;\r\n}\r\n\r\ndiv.album.photo-shoot {\r\n  display: inline;\r\n}\r\n\r\n.album.photo-shoot img {\r\n  margin: 3px;\r\n  width: 90px;\r\n  object-fit: cover;\r\n}\r\n\r\n.extra-space {\r\n  height: 80vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
